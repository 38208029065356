import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.png"

const links = [
  {
    label: "SERVICII",
    title: "Execuție rețea fibră optică",
    url: "/",
  },
  {
    label: "VALORI",
    title: "",
    url: "/valori",
  },
  {
    label: "CARIERE",
    title: "Jonctor fibră optică",
    url: "/tehnician-telecomunicatii",
  },
  {
    label: "CONTACT",
    title: "Solicitare ofertă execuție rețea fibră optică",
    url: "/contact",
  },
]
const Header = ({ siteTitle }) => (
  <>
    <nav
      className="navbar navbar-light"
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        // padding: `1.45rem 1.0875rem`,
      }}
    >
      <Link className="navbar-brand" to="/">
        <img
          src={logo}
          width="100"
          height="100"
          className="d-inline-block align-top"
          alt="TCNET"
          title="TCNET - execuție rețele fibră optică"
        />
      </Link>
      {/*<span className="navbar-text">TC Networks</span>*/}
      <ul className="nav justify-content-end">
        {links.map(link => (
          <li key={link.label} className="nav-item">
            <Link
              className="nav-link"
              // style={{ color: "#335EEA" }}
              title={link.title}
              to={link.url}
            >
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
    <header
      style={
        {
          // background: `rebeccapurple`,
          // marginBottom: `1.45rem`,
        }
      }
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          // padding: `1.45rem 1.0875rem`,
        }}
      >
        {/*<Link*/}
        {/*  to="/"*/}
        {/*  style={{*/}
        {/*    color: `white`,*/}
        {/*    textDecoration: `none`,*/}
        {/*  }}*/}
        {/*>*/}
        {/* */}
        {/*</Link>*/}
      </div>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
