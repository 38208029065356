// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

export default () => (
  <footer
    style={{
      background: `#2A354F`,
      marginBottom: 0,
    }}
  >
    <div
      style={{
        color: `white`,
        // fontSize: "0.9rem",
        margin: `0 auto`,
        maxWidth: 960,
        padding: `2rem 1.0875rem`,
      }}
    >
      <address style={{ lineHeight: "1.45rem", marginBottom: 0 }}>
        <strong>Telecommunication Networks SRL</strong> <br />
        RO40853260 - J22/1384/2019 <br />
        Șos. Bucium 23, clădire C18, Iași, România
      </address>
    </div>
  </footer>
)
